// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Iconoir } from "https://framerusercontent.com/modules/zL9598C4KbEbqUGvSR14/rI8sPHpnG9XGcCPc0vU4/Iconoir.js";
const IconoirFonts = getFonts(Iconoir);
const IconoirControls = getPropertyControls(Iconoir);

const enabledGestures = {cN1Pi3q8C: {hover: true}};

const cycleOrder = ["cN1Pi3q8C"];

const variantClassNames = {cN1Pi3q8C: "framer-v-16qe2w3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({color, height, icon, id, link, width, ...props}) => { return {...props, CHkAsBGIS: icon ?? props.CHkAsBGIS ?? "Facebook", IwRpjY7bg: color ?? props.IwRpjY7bg ?? "rgb(255, 255, 255)", mkh_zmIOu: link ?? props.mkh_zmIOu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;link?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CHkAsBGIS, mkh_zmIOu, IwRpjY7bg, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cN1Pi3q8C", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jEfsK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={mkh_zmIOu} openInNewTab><motion.a {...restProps} className={`${cx("framer-16qe2w3", className)} framer-1jcxvg5`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cN1Pi3q8C"} ref={ref} style={{opacity: 1, ...style}} variants={{"cN1Pi3q8C-hover": {opacity: 0.37}}} {...addPropertyOverrides({"cN1Pi3q8C-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div __perspectiveFX={false} __targetOpacity={1} className={"framer-4lrrnc-container"} layoutDependency={layoutDependency} layoutId={"ku6WyJa4d-container"} transformTemplate={transformTemplate}><Iconoir color={IwRpjY7bg} height={"100%"} iconSearch={"Home"} iconSelection={CHkAsBGIS} id={"ku6WyJa4d"} layoutId={"ku6WyJa4d"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jEfsK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jEfsK .framer-1jcxvg5 { display: block; }", ".framer-jEfsK .framer-16qe2w3 { height: 48px; overflow: hidden; position: relative; text-decoration: none; width: 48px; }", ".framer-jEfsK .framer-4lrrnc-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-jEfsK .framer-v-16qe2w3 .framer-16qe2w3 { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rW87_Zwlo":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CHkAsBGIS":"icon","mkh_zmIOu":"link","IwRpjY7bg":"color"}
 */
const FramerKZfehYOr8: React.ComponentType<Props> = withCSS(Component, css, "framer-jEfsK") as typeof Component;
export default FramerKZfehYOr8;

FramerKZfehYOr8.displayName = "Icon";

FramerKZfehYOr8.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerKZfehYOr8, {CHkAsBGIS: IconoirControls?.["iconSelection"] && {...IconoirControls["iconSelection"], defaultValue: "Facebook", hidden: undefined, title: "Icon"}, mkh_zmIOu: {title: "Link", type: ControlType.Link}, IwRpjY7bg: {defaultValue: "rgb(255, 255, 255)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerKZfehYOr8, [...IconoirFonts])